import React from "react";
import { wait, reachGoal } from "../../utils";

import "./index.css";

const PROJECT_ID = `${process.env.REACT_APP_WEBPUSH_PROJECT_ID || ""}`;

export default class SmiWebpush extends React.Component {
  componentDidMount() {
    if (!PROJECT_ID) {
      return;
    }

    initScript(() => {
      const initConfig = {
        projectId: PROJECT_ID,
        subscribeOnPageLoad: true,
        subscribeOnPageLoadDelay: 100,
        workerPath: "/sw.js",
        widgets: {},
        onSuccessCallback: function () {
          console.log("Subscription was successful");
        },
        onFailedCallback: function () {
          console.log('Subscription failed');
        },
        onDeniedCallback: function () {
          console.log('Subscription was denied');
        },
        onSubscribedCallback: function () {
          console.log('Subscription was saved on server');
        }
      };
      window.ytrgt.webpush.init(initConfig); // Перемещено сюда
    });
  }
  
  render() {
    return null;
  }
}

function initScript(callback) {
  const n = document.getElementsByTagName("script")[0];
  const s = document.createElement("script");
  s.type = "text/javascript";
  s.async = true;
  s.src = "https://sdk.justpush.ai/js/v0/webpush.js";
  s.onload = callback; 
  n.parentNode.insertBefore(s, n);
}
