import React from "react";

import { loadConfig, adblockDetect } from "api";
import { asyncComponent, getFavicon } from "utils";
import Redirect from "./components/Redirect";

import LiRuCounter from "./components/LiRuCounter";
import YaMetrica from "./components/YaMetrica";
import SmiWebpush from "./components/SmiWebpush";
import { ConfigContext } from "./context";
import getConfig from "config";
import { TrackerProvider } from "./components/TrackerProvider";

import { BackRedirectDetect } from "handlers/BackRedirect";

/* 120X */
const Top1201 = asyncComponent(() => import("views/top1201"));
const Top1202 = asyncComponent(() => import("views/top1202"));
const Top1203 = asyncComponent(() => import("views/top1203"));
const Top1204 = asyncComponent(() => import("views/top1204"));
const Top1205 = asyncComponent(() => import("views/top1205"));
const Top1206 = asyncComponent(() => import("views/top1206"));
const Top1207 = asyncComponent(() => import("views/top1207"));
const Top1208 = asyncComponent(() => import("views/top1208"));
const Top1209 = asyncComponent(() => import("views/top1209"));

/* 130X */
const Top1301 = asyncComponent(() => import("views/top1301"));


const WEBPUSH_DISABLED_SOURCES = `${process.env
  .REACT_APP_WEBPUSH_DISABLED_SOURCES || ""}`.split(",");

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      configured: false,
      configuration: {},
      mainNews: null,
      yaCounterReady: false,
      redirect: false
    };

    document.addEventListener(
      `yacounter${process.env.REACT_APP_YA_COUNTE_ID}inited`,
      () => {
        this.setState({ yaCounterReady: true });
      }
    );
  }

  updateMainNews(mainNews, cb) {
    this.setState(() => {
      return { mainNews };
    }, cb);
  }

  getView() {
    switch (this.state.configuration.template) {

      case 1201:
        return Top1201;

      case 1202:
        return Top1202;

      case 1203:
        return Top1203;

      case 1204:
        return Top1204;

      case 1205:
        return Top1205;

      case 1206:
        return Top1206;

      case 1207:
        return Top1207;

      case 1208:
        return Top1208;

      case 1209:
        return Top1209;

      case 1301:
        return Top1301;

      default:
        return Top1203;
    }
  }

  componentDidMount() {
    document.getElementById("favicon").setAttribute("href", getFavicon());

    adblockDetect().then(detected => {
      window.ab = detected;

      loadConfig().then(config => {
        if (window.parent === window.self) {
          const url = new URL(window.location.href);

          if (config.config.click_id) {
            url.searchParams.set("click_id", config.config.click_id);
          }

          if (config.config.domain) {
            url.searchParams.set("domain", config.config.domain);
          }

          window.history.replaceState(null, "", url.toString());
        }

        const nextState = {
          configured: true,
          configuration: config.config,
          mainNews: config.teaser || null,
          redirect: config.redirect || false
        };
        this.setState(nextState);

        const brd = new BackRedirectDetect(
          nextState.mainNews,
          this.getConfiguration()
        );
        brd.setInfo();
      });
    });
  }

  getConfiguration() {
    return getConfig(this.state.configuration);
  }

  render() {
    if (!this.state.configured) {
      return null;
    }

    if (this.state.redirect) {
      return (
        <React.Fragment>
          <Redirect url={this.state.redirect} />
        </React.Fragment>
      );
    }

    const { cid, template } = this.state.configuration;

    const enableLiRu = true;
    const enableYaMetrica = true;
    const enableSmiWebpush = true;

    const View = this.getView();

    const config = this.getConfiguration();

    return (
      <ConfigContext.Provider value={config}>
        {enableLiRu && <LiRuCounter />}
        {enableYaMetrica && <YaMetrica />}
        {enableSmiWebpush && <SmiWebpush />}

        <TrackerProvider>
          <View
            configuration={config}
            mainNews={this.state.mainNews}
            updateMainNews={this.updateMainNews.bind(this)}
            yaCounterReady={this.state.yaCounterReady}
            enableYaMetrica={enableYaMetrica}
          />
        </TrackerProvider>
      </ConfigContext.Provider>
    );
  }
}

export default App;
